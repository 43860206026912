import { appRootId } from "./components/shared";

export const getLocalApplicationMetadata = () => {
    const appRoot = document.getElementById(appRootId)!;

    const environment = appRoot.attributes.getNamedItem("data-app_env")?.value;

    const version = appRoot.attributes.getNamedItem("data-app_version")?.value;

    const builded = appRoot.attributes.getNamedItem("data-app_build")?.value;

    const year = new Date(builded ? parseInt(builded) : Date.now()).getFullYear();

    return { environment, version, year };
}