import { DextallGeneratorInterop } from "@dextall/interop";
import { SystemType } from "./project";

export enum ModelTranslationStatus {
    Created = 1,

    Ready = 2,

    Failed = 3
}

export enum ModelWorkflowStatus {
    Draft = 1,

    Submitted = 2,

    Approved = 3,

    Declined = 4,

    EngineerDraft = 5,

    Bid = 6
}

export interface IModel3D {
    id: string;
    name: string;
    originalFileName: string;
    versions: IModel3DVersion[];
}

export interface IModel3DVersion {
    id: string;
    fileName: string;
    originalFileName: string;
    urn: string | null;
    status: ModelTranslationStatus;
    workflowStatus: ModelWorkflowStatus;
    systemType: DextallGeneratorInterop.Entities.Inputs.SystemType;
    forgeStatus: string | null;
    forgePercentage: number;
    createdAt: Date;
    versionNumber: number;
    parentVersionNumber: number | null;
    isRetrofitModel: boolean;
    offsetFromWall: number;
    isMainOption: boolean;
    optionName: string | null;
    approvedAt: Date | null;
    comment: string | null;
    wallsOffset: ModelPrepareWallsOffset;
    externalWallTypeNameStartsWith: string | null;
}

export interface IModelStorageObject {
    objectKey: string;
    signedUrl: string;
}

export interface IEngineeringProductionModel {
    id: string;
    urn: string;
    name: string;
    version: number;
    systemType: SystemType;
    architect: string;
}

export enum ModelPrepareWallsOffset {
    None = 0,

    Inch1 = 1,

    Inch2 = 2,

    Inch3 = 3,

    Inch4 = 4,

    Inch5 = 5,

    Inch6 = 6,

    Acm = 7
}

export type UploadFileRequest = {
    file: File;
}

export type ModelPrepareOptions = {
    wallsOffset: ModelPrepareWallsOffset;
    externalWallTypeNameStartsWith: string;
}

export type UploadModelFileRequest = UploadFileRequest & ModelPrepareOptions & { projectId: string; }

export type UploadLinkedModelFileRequest = UploadFileRequest & { modelId: string; }

export type CreateLinkedModelCommand = {
    fileName: string;
    objectKey: string;
}

export type CreateSourceModelCommand = CreateLinkedModelCommand & ModelPrepareOptions;

export type RevitModelExtension = "rvt";

export type LinkedModelExtension = RevitModelExtension | "ifc";

export interface ILinkedModel {
    id: string;
    fileName: string;
    urn: string;
    status: ModelTranslationStatus;
    origin: THREE.Vector3;
    createdAt: Date;
    translationProgressPercentage: number;
}

export type AssignLinkedModelCommand = {
    modelId: string;
    primaryLinkedModelId: string;
}