import React from "react";

type Styles = {
    container: React.CSSProperties;
    versions: React.CSSProperties;
    ellipsisStyle: React.CSSProperties;
    modelInfoDialog: React.CSSProperties;
    xButton: React.CSSProperties;
    deleteButton: React.CSSProperties;
    modelInfoButton: React.CSSProperties;
    modelTitle: React.CSSProperties;
    modelName: React.CSSProperties;
    modelNameInEdit: React.CSSProperties;
    optionsTitle: React.CSSProperties;
    infoTitle: React.CSSProperties;
    modelNameEdit: React.CSSProperties;
    infoText: React.CSSProperties;
    editButton: React.CSSProperties;
}

export const styles: Styles = {
    container: {
        marginLeft: 5,
        maxHeight: "calc(100vh - 120px)",
        width: "245px",
        boxSizing: "border-box",
        display: "flex",
        flexFlow: "column",
    },
    modelTitle: {
        display: "flex",
        justifyContent: "space-between"
    },
    versions: {
        padding: "0px 9px 15px", 
        display: "flex", 
        flexDirection: "column", 
        overflowY: "scroll", 
        overflowX: "hidden", 
        fontSize: "15px",
    },
    ellipsisStyle: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: "200px",
        marginRight: 8,
    },
    modelInfoDialog: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 25
    },
    xButton: {
        backgroundColor: "#FFCACA", 
        padding: "7px", 
        marginLeft: 8,
        marginRight: 8, 
        position: "absolute", 
        top: 20, 
        right: 5 
    },
    deleteButton: {
        padding: 8, 
        height: 37, 
        marginTop: 15, 
        marginRight: 15
    },
    modelInfoButton: {
        padding: "5px", 
        marginTop: 12, 
        height: 30, 
        marginRight: 10
    },
    modelName: {
        padding: "16px 14px 0",
        fontSize: "16px"
    },
    modelNameInEdit: {
        fontSize: "14px",
        fontWeight: "inherit" 
    },
    optionsTitle: {
        padding: "8px 14px 12px",
        fontSize: "16px"
    },
    infoTitle: {
        textAlign: "center",
        padding: "0px 70px 30px 60px"
    },
    modelNameEdit: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8 
    },
    infoText: {
        fontSize: "14px",
        marginBottom: 8
    },
    editButton: {
        cursor: 'pointer',
        marginRight: 12
    }
};