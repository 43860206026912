import { Dialog } from "@mui/material";
import { AppButton } from "./appButton";
import { TextH3 } from "../assets/text";
import { Model3D } from "../reducers/modelsReducer";
import { styles } from "../assets/optionSwitcherStyles";
import { X, Edit, Trash } from "react-feather";
import ModelNameEditor from './modelNameEditor';
import { colors, card } from '../config';
import ModelDownload from './modelDownload';

type ModelInfoDialogProps = {
    model: Model3D;
    isOpen: boolean;
    onClose: () => void;
    isInModelNameEdit: boolean;
    setIsInModelNameEdit: (isEditing: boolean) => void;
    wallsOffsetString: string;
    requestModelDeletion: () => void;
    canRemoveModel: boolean;
    handleEditingDone: () => void;
    isEditHovered: boolean;
    setEditHovered: (isHovered: boolean) => void;
    trashButtonState: any;
}

export const ModelInfoDialog = ({
    model,
    isOpen,
    onClose,
    isInModelNameEdit,
    setIsInModelNameEdit,
    wallsOffsetString,
    requestModelDeletion,
    canRemoveModel,
    handleEditingDone,
    isEditHovered,
    setEditHovered,
    trashButtonState
}: ModelInfoDialogProps) => {
    return (
        <Dialog open={isOpen}>
            <div style={styles.modelInfoDialog}>
                <TextH3 style={styles.infoTitle}>{model.name} information</TextH3>
                <AppButton
                    style={styles.xButton}
                    onClick={onClose}
                >
                    <X size={18} stroke={"#C93636"} />
                </AppButton>
                <div style={styles.infoText}>
                    Primary Cladding Depth: {wallsOffsetString}
                </div>
                <div style={styles.infoText}>
                    Original File Name: {model.originalFileName}
                </div>
                <div style={styles.modelNameEdit}>
                    <span style={{ ...styles.ellipsisStyle, minWidth: isInModelNameEdit ? "-webkit-fill-available" : "" }}>
                        {isInModelNameEdit ? (
                            <ModelNameEditor
                                model={model}
                                onEditingDone={handleEditingDone}
                            />
                        ) : (
                            <TextH3 style={styles.modelNameInEdit}>{model.name}</TextH3>
                        )}
                    </span>
                    {!isInModelNameEdit && (
                        <span style={styles.editButton}
                            onMouseEnter={() => setEditHovered(true)}
                            onMouseLeave={() => setEditHovered(false)}
                            onClick={() => setIsInModelNameEdit(true)}
                        >
                            <Edit size={11} stroke={isEditHovered ? colors.hovered : colors.subheading} />
                        </span>
                    )}
                </div>
                <div style={{ display: "flex" }}>
                    {canRemoveModel && <AppButton
                        style={{ backgroundColor: card.versionProject, ...styles.deleteButton }}
                        onClick={() => requestModelDeletion()}
                        colored={true}
                        hovered={trashButtonState.isHovered}
                        handleMouseEnter={trashButtonState.handleMouseEnter}
                        handleMouseLeave={trashButtonState.handleMouseLeave}
                        selected={trashButtonState.isSelected}
                        handleMouseDown={trashButtonState.handleMouseDown}
                        handleMouseUp={trashButtonState.handleMouseUp}
                        prompt="Delete Model"
                    >
                        <Trash size={20} style={{ stroke: trashButtonState.isSelected ? colors.textBlack : trashButtonState.isHovered ? colors.hovered : colors.subheading }} />
                    </AppButton>}
                    <ModelDownload />
                </div>
            </div>
        </Dialog>
    );
};
