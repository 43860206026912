import { useEffect, useRef } from "react"
import { connect, useDispatch } from 'react-redux';
import { toggleHighLodMode } from "../actions/sourceRevitModelsActions";
import { reportProcessingProgress, showProcessingPane, showProcessingError } from "../actions/processingActions";
import { requireAnalyticsRefresh } from "../actions/modelsAnalyticsActions";
import { setSaving, setModelCycleError } from "../actions/modelSaveCycleActions";
import {
    areUnreleasedFeaturesAvailable,
    getApplicationUserName,
    getCurrentProject,
    getInsulatedGlassUnits,
    getModels,
    getSelectedModelVersion,
    isAnalyticsTabVisible,
    isEditable,
    isEngineer,
    isHighLodModelViewMode
} from "../reducers/mainReducer";
import { ModelSaveCycle } from "./modelSaveCycle";
import { createModelSelectionLock } from "./forge/viewer-utils/viewerModelSelectionLock";
import { ForgeViewer, ViewerState } from "./forgeViewer";
import { PanelsCollector } from "./forge/panelsCollector";
import { IModel3D, IModel3DVersion, ModelWorkflowStatus } from "./model3d";
import { IProject } from "./project";
import { IInsulatedGlassUnit } from "./forge/insulatedGlassUnit";
import { LoadingExtension, loadViewerExtension, loadViewerExtensions } from "./forgeExtensionsLoader";
import { ModelBufferedGeometry } from "./forge/modelBufferedGeometry";
import { setViewerToolsModality } from "./forge/viewer-utils/viewerToolsModality";
import { WallFacesCollection } from "./forge/wallFacesCollection";
import { modalsContainerId } from "./shared";
import { ModelsGenerator } from "./forge/workers/modelsGenerator";
import ModelSaveFailuresNotifications from "./modelSaveFailuresNotifications";
import usedColorsCache from "./ralColorSelectorUsedColorsCache";
import repo from '../Repository';
import userIds from "./forge/userObjectIds";
import "./modelEditorViewer.css"

interface IComponentProps {
    project: IProject | null;
    allModels: IModel3D[];
    insulatedGlassUnits: IInsulatedGlassUnit[];
    selectedModel: IModel3DVersion | null;
    isHighLodMode: boolean;
    isAnalyticsTabVisible: boolean;
    areUnreleasedFeaturesAvailable: boolean;
    isEngineer: boolean;
    editable: boolean;
    userName: string;

    showProcessingPane: (visible: boolean, title?: string) => void;
    reportProcessingProgress: (message: string) => void;
    showProcessingError: (title: string, message: string) => void;
    toggleHighLodMode: (mode: boolean) => void;
    requireAnalyticsRefresh: () => void;
}

const loadApplicationData = async (modelId: string) => {
    const [panels, corners, wallFaces, modelCorners, modelWindows, windowTemplates, panelTemplates, cornerPanelTemplates, linkedModelsResponse, windowTypes, systemSettings] = await Promise.all([
        repo.findPanels(modelId),
        repo.findCornerPanels(modelId),
        repo.findModelWallFaces(modelId),
        repo.findModelCorners(modelId),
        repo.findModelWallWindows(modelId),
        repo.findWindowTemplates(),
        repo.findPanelTemplates(modelId),
        repo.findCornerTemplates(),
        repo.findLinkedModels(modelId),
        repo.findWindowTypes(modelId),
        repo.findModelSystemSettings(modelId),
    ]);

    return {
        panels,
        corners,
        wallFaces,
        modelCorners,
        modelWindows,
        windowTemplates,
        panelTemplates,
        cornerPanelTemplates,
        linkedModels: linkedModelsResponse.items || [],
        windowTypes: windowTypes.items || [],
        systemSettings
    };
}

const setToolModality = (viewer: Autodesk.Viewing.GuiViewer3D) => {
    const toolNames = [
        "wall-panel-cladding-cell-splitter",
        "wall-panel-cladding-cell-merger",
        "wall-new-panel-position-selector",
        "wall-corner-selector",
        "dextall-format-panels-by-selection-tool",
        "dextall-objects-alignment-tool",
        "dextall-panels-grid-selection-3d-tool",
        "dextall-panels-grid-mover-3d-tool"
    ];

    setViewerToolsModality(viewer, toolNames);
}

type Disposable = {
    dispose(): void;
}

type ImmediateDisposable = {
    immediateDispose(): void;
}

type EditableStatus = {
    initEditableStatus(editable: boolean): void;
}

const ModelEditorViewer = (props: IComponentProps) => {
    const disposableObjects = useRef<Disposable[]>();
    const immediateDisposableObjects = useRef<ImmediateDisposable[]>();

    const editable = useRef<EditableStatus>()
    const dispatch = useDispatch();

    useEffect(() => {
        if (editable.current != null) {
            editable.current.initEditableStatus(props.selectedModel!.workflowStatus === ModelWorkflowStatus.Draft || (props.isEngineer && props.selectedModel!.workflowStatus === ModelWorkflowStatus.EngineerDraft));
        }
    }, [props.selectedModel?.workflowStatus]);

    const toggleViewMode = () => {
        const model = props.selectedModel;

        if (!model)
            return;

        props.toggleHighLodMode(!props.isHighLodMode);
    }

    const extendViewer = async (viewer: Autodesk.Viewing.GuiViewer3D, urn: string, state: ViewerState) => {
        if (!(urn === props.selectedModel?.urn && viewer.impl))
            return;

        const modelId = props.selectedModel!.id;

        const spinner = new window.Autodesk.Viewing.UI.LoadingSpinner(viewer.container);

        spinner.addClass("viewer-loading-spinner-container");
        spinner.setVisible(true);

        userIds.panelUserIds.reset();
        userIds.cornerUserIds.reset();
        userIds.windowUserIds.reset();

        const selectionLock = await createModelSelectionLock(viewer, viewer.model)();

        selectionLock.lock();

        const { panels, corners, wallFaces, modelCorners, modelWindows, windowTemplates,
            panelTemplates, cornerPanelTemplates, linkedModels, windowTypes, systemSettings } = await loadApplicationData(modelId);

        if (props.selectedModel?.urn !== urn) return;

        const renderingExtension = await viewer.loadExtension("Autodesk.NPR") as Autodesk.Extensions.NPR.NPRExtension;

        renderingExtension.setParameter("style", "edging");
        renderingExtension.setParameter("edges", false);
        renderingExtension.setParameter("preserveColor", false);

        const modelSaveCycle = new ModelSaveCycle(modelId);

        const sceneBuilder = await viewer.loadExtension("Autodesk.Viewing.SceneBuilder") as Autodesk.Viewing.SceneBuilder;

        const modelBuilder = await sceneBuilder.addNewModel({ modelNameOverride: "dextall", conserveMemory: true, createWireframe: false });

        const modelBufferedGeometry = new ModelBufferedGeometry(viewer, modelBuilder);

        disposableObjects.current = [modelBufferedGeometry];

        if (state.shutdownSignaled)
            return;

        const aec = await window.Autodesk.Viewing.Document.getAecModelData(viewer.model.getDocumentNode());

        if (props.selectedModel?.urn !== urn || state.shutdownSignaled)
            return;

        await import("./forge/extensions/index");

        if (props.selectedModel?.urn !== urn || state.shutdownSignaled)
            return;

        const wallFacesCollection = await WallFacesCollection.create(wallFaces, viewer.model);

        disposableObjects.current = [modelBufferedGeometry, wallFacesCollection];

        const editorsFactoryModule = await import("./forge/modelEditorsFactory");

        const projectInsulatedGlassUnitId = props.project?.selectedInsulatedGlassUnit?.id || props.insulatedGlassUnits[0].id;

        const editorsFactory = new editorsFactoryModule.ModelEditorsFactory(viewer, aec, modelSaveCycle, wallFacesCollection, modelCorners, systemSettings,
            modelBufferedGeometry, props.selectedModel.offsetFromWall, props.selectedModel!.systemType, projectInsulatedGlassUnitId, props.userName,
            props.selectedModel!.workflowStatus === ModelWorkflowStatus.Draft);

        const editableStatusModule = await import("./forge/EditableStatus");

        editable.current = new editableStatusModule.EditableStatus(editorsFactory.eventBus);

        modelSaveCycle.start(editorsFactory.eventBus);

        editorsFactory.eventBus.addEventListener("Dextall.ModelSaveCycle.ItemQueued", () => dispatch(setSaving(true)));
        editorsFactory.eventBus.addEventListener("Dextall.ModelSaveCycle.FlushedAll", () => {
            dispatch(setSaving(false));
            dispatch(setModelCycleError(null));
        });
        editorsFactory.eventBus.addEventListener("Dextall.ModelSaveCycle.Error", (eventData) => dispatch(setModelCycleError(eventData.payload)));

        disposableObjects.current = [editorsFactory, modelBufferedGeometry, wallFacesCollection];
        immediateDisposableObjects.current = [editorsFactory];

        await loadViewerExtension(viewer, {
            id: "Dextall.ForgeModelWarningsControllerExtension",
            loadOptions: { eventBus: editorsFactory.eventBus }
        })

        if (props.selectedModel?.urn !== urn || state.shutdownSignaled)
            return;

        const panelEditor = await editorsFactory.createPanelModelEditor();

        disposableObjects.current = [editorsFactory, modelBufferedGeometry, panelEditor, wallFacesCollection];

        if (state.shutdownSignaled)
            return;

        const cornerEditor = await editorsFactory.createCornerModelEditor();

        disposableObjects.current = [editorsFactory, modelBufferedGeometry, panelEditor, cornerEditor, wallFacesCollection];

        if (state.shutdownSignaled)
            return;

        const windowEditor = editorsFactory.createWindowModelEditor();

        const wallsEditor = await editorsFactory.createWallsModelEditor();

        const modelsGenerator = new ModelsGenerator(editorsFactory.eventBus, modelId);

        disposableObjects.current = [editorsFactory, modelBufferedGeometry, panelEditor, cornerEditor, wallsEditor, windowEditor, wallFacesCollection, modelsGenerator];

        if (state.shutdownSignaled)
            return;

        const panelsGeneratorInterop = await editorsFactory.loadPanelsGeneratorInterop();

        const panelsValidator = await editorsFactory.createValidator();

        const wallSectionViewer = editorsFactory.createWallSectionViewer();

        await wallsEditor.init();

        const panelTypesController = editorsFactory.panelTypesController;

        panelTypesController.statuses.initialize(panels, corners);

        if (state.shutdownSignaled)
            return;

        const showProcessingPane = (visible: boolean, title?: string) => props.showProcessingPane(visible, title);
        const reportProcessingProgress = (message: string) => props.reportProcessingProgress(message);
        const showProcessingError = (title: string, message: string) => props.showProcessingError(title, message);

        const setRefreshNeeded = () => props.requireAnalyticsRefresh();


        const isEditable = props.selectedModel!.workflowStatus === ModelWorkflowStatus.Draft || (props.isEngineer && props.selectedModel!.workflowStatus === ModelWorkflowStatus.EngineerDraft);

        const extensions: LoadingExtension[] = [
            {
                id: "Dextall.ForgeWallsEditorExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    wallsModelEditor: wallsEditor,
                    panelsCollector: editorsFactory.panelsCollector,
                    wallFaces: wallFacesCollection,
                    panelEditor,
                    cornerEditor,
                    windowEditor,
                    modelSaveCycle,
                    systemSettings,
                    projectInsulatedGlassUnitId,
                    aec
                }
            },
            {
                id: "Dextall.ForgeWallsEditorUndoRedoExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus
                }
            },
            {
                id: "Dextall.ForgePanelEditorExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    panelEditor,
                    cornerEditor,
                    modelSaveCycle,
                    panels,
                    wallFaces: wallFacesCollection,
                    aec,
                    panelsCollector: editorsFactory.panelsCollector,
                    isRetrofitModel: props.selectedModel.isRetrofitModel,
                    systemSettings,
                    colorsFactory: editorsFactory.colorsFactory,
                    editable: isEditable
                }
            },
            {
                id: "Dextall.ForgeCornerEditorExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    cornerEditor,
                    panelEditor,
                    modelSaveCycle,
                    corners,
                    modelCorners,
                    isRetrofitModel: props.selectedModel.isRetrofitModel,
                    editable: isEditable
                }
            },
            {
                id: "Dextall.ForgeSearchElementsByIdExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    panelEditor,
                    cornerEditor,
                    windowEditor
                }
            },
            {
                id: "Dextall.ForgePanelsGridEditorExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    wallFaces: wallFacesCollection,
                    panelsCollector: editorsFactory.panelsCollector,
                    cornerWingDimensions: panelsGeneratorInterop,
                    systemSettings,
                    wallsModelEditor: wallsEditor,
                    editable: isEditable
                }
            },
            {
                id: "Dextall.ForgeAutomaticalPanelsLayoutExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    modelBuilder,
                    wallFaces: wallFacesCollection,
                    modelCorners,
                    aec,
                    showProcessingPane,
                    reportProcessingProgress,
                    selectionLock,
                    systemSettings,
                    panelsGeneratorInterop,
                    panelsCollector: editorsFactory.panelsCollector,
                    editable: isEditable
                }
            },
            {
                id: "Dextall.ForgeAutomaticalPanelsLayoutAssistantExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    modelCorners,
                    aec,
                    wallSectionViewer,
                    panelsGeneratorInterop,
                    processingPaneControl: { showProcessingPane, reportProcessingProgress },
                    panelsCollector: editorsFactory.panelsCollector,
                    systemSettings,
                    projectInsulatedGlassUnitId,
                    wallFaces: wallFacesCollection
                }
            },
            {
                id: "Dextall.ForgeApplyTypeToSimilarPanelsExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    panelsCollector: editorsFactory.panelsCollector,
                    colorsFactory: editorsFactory.colorsFactory
                }
            },
            {
                id: "Dextall.ForgeWindowsEditorExtension",
                loadOptions: {
                    modelId,
                    eventBus: editorsFactory.eventBus,
                    modelWindows,
                    windowEditor,
                    windowTemplates,
                    windowTypes,
                    editable: isEditable,
                    areUnreleasedFeaturesAvailable: props.areUnreleasedFeaturesAvailable,
                    insulatedGlassUnits: props.insulatedGlassUnits,
                    projectInsulatedGlassUnitId
                }
            },
            {
                id: "Dextall.ForgeAlignmentExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    wallFaces: wallFacesCollection,
                    panelsCollector: editorsFactory.panelsCollector,
                    panelsModelId: modelBufferedGeometry.model.id,
                    editable: isEditable
                }
            },
            {
                id: "Dextall.ForgeUndoRedoExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    showProcessingPane,
                    reportProcessingProgress
                }
            },
            {
                id: "Dextall.ForgePanelsPropertiesExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus
                }
            },
            {
                id: "Dextall.ForgePanelCatalogsExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    panelTemplates,
                    cornerPanelTemplates,
                    panelsCollector: editorsFactory.panelsCollector,
                    systemSettings,
                    colorsFactory: editorsFactory.colorsFactory
                }
            },
            {
                id: "Dextall.ForgeBuildingWideChangeExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    panelModelEditor: panelEditor,
                    cornerModelEditor: cornerEditor,
                    windowModelEditor: windowEditor,
                    panelsCollector: editorsFactory.panelsCollector,
                    editable: isEditable
                }
            }, {
                id: "Dextall.ForgeLinkedModelsExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    linkedModels,
                    modelId,
                    projectId: props.project?.id || ""
                }
            },
            {
                id: "Dextall.ForgeContextMenusExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus
                }
            },
            {
                id: "Dextall.ForgeAnalyticsRefreshExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    setRefreshNeeded
                }
            },
            {
                id: "Dextall.ForgeAtypicalSolutionsListExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    panelModelEditor: panelEditor,
                    cornerModelEditor: cornerEditor,
                    model: modelBufferedGeometry.model
                }
            },
            {
                id: "Dextall.ForgeTypesManagerExtension",
                loadOptions: {
                    modelId,
                    eventBus: editorsFactory.eventBus,
                    panelsCollector: editorsFactory.panelsCollector,
                    panelsModel: modelBufferedGeometry.model,
                    editable: isEditable,
                    panelModelEditor: panelEditor,
                    cornerModelEditor: cornerEditor,
                    panelTypesController,
                    modelsGenerator,
                    panelsValidator,
                    showProcessingError
                }
            },
            {
                id: "Dextall.ForgePanelPreviewExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    panelsCollector: editorsFactory.panelsCollector,
                    isEngineer: props.isEngineer,
                    editable: isEditable
                }
            },
            {
                id: "Dextall.ForgeViewerModeSwitcherExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    toggleViewMode: toggleViewMode.bind(this),
                    buttonIconClass: "viewer-editor-toggle-high-lod-mode",
                    buttonTooltip: "Switch to high LOD panels viewer"
                }
            },
            {
                id: "Dextall.ForgeInteractiveBomExtension",
                loadOptions: {
                    eventBus: editorsFactory.eventBus,
                    modelId,
                    highLodMode: false
                }
            }
        ];

        // TODO: once released for all users 
        // add to the extensions list before "Dextall.ForgeBuildingWideChangeExtension"
        if (props.areUnreleasedFeaturesAvailable) {
            const index = extensions.findIndex(x => x.id === "Dextall.ForgeBuildingWideChangeExtension");

            const extension: LoadingExtension = {
                id: "Dextall.ForgeApplyDesignFromAnotherModelExtension",
                loadOptions: {
                    editable: isEditable,
                    modelId,
                    models: props.allModels,
                    wallFaces: wallFacesCollection,
                    systemSettings,
                    projectInsulatedGlassUnitId,
                    panelsCollector: editorsFactory.panelsCollector,
                    eventBus: editorsFactory.eventBus
                }
            }

            extensions.splice(index, 0, ...[extension]);
        }

        const loadExtensions = loadViewerExtensions.bind(null, props)

        await loadExtensions(viewer, extensions, urn, state);

        if (props.selectedModel?.urn !== urn || state.shutdownSignaled)
            return;

        const panelsOverlapsSpyModule = await import("./forge/panelsOverlapsSpy");

        const panelsCollector = editorsFactory.panelsCollector as PanelsCollector;

        const panelsOverlapsSpy = new panelsOverlapsSpyModule.PanelsOvelapsSpy(panelsCollector, modelBuilder.model, editorsFactory.eventBus);

        disposableObjects.current = [editorsFactory, panelsOverlapsSpy, modelBufferedGeometry, panelEditor, cornerEditor, wallsEditor, windowEditor, wallFacesCollection, modelsGenerator];

        panelsOverlapsSpy.spy();

        setToolModality(viewer);

        const forceDraw = () => {
            panelsCollector.panelModelEditor?.forceDraw();
            panelsCollector.cornerModelEditor?.forceDraw();
            panelsCollector.windowModelEditor?.forceDraw();
        }

        forceDraw();

        if (state.shutdownSignaled)
            return;

        usedColorsCache.dispose();
        usedColorsCache.use(editorsFactory.colorsFactory.getLastUsedColorCodes());

        if (props.areUnreleasedFeaturesAvailable) {
            viewer.prefs.set(Autodesk.Viewing.Private.Prefs.PROGRESSIVE_RENDERING, false);
        }

        spinner.setVisible(false);
    }

    const cleanup = () => {
        if (!disposableObjects.current)
            return;

        for (const disposableObject of disposableObjects.current)
            disposableObject.dispose();

        disposableObjects.current = [];
    }

    const immediateCleanup = () => {
        if (!immediateDisposableObjects.current)
            return;

        for (const disposableObject of immediateDisposableObjects.current)
            disposableObject.immediateDispose();

        immediateDisposableObjects.current = [];
    }

    return (<>
        {props.selectedModel?.urn && <>
            <ForgeViewer
                id={props.selectedModel.id}
                urn={props.selectedModel.urn}
                analyticsVisible={props.isAnalyticsTabVisible}
                defaultExtensions={defaultExtensions}
                extendViewer={extendViewer}
                cleanup={cleanup}
                immediateCleanup={immediateCleanup}
                hiddenCategories={["Levels", "Windows", "Doors"]}
            />
            <div className="model-editor-viewer-modals-container" id={modalsContainerId}></div>
            <ModelSaveFailuresNotifications />
        </>}
    </>)
}

const defaultExtensions = ["Autodesk.Viewing.SceneBuilder", "Autodesk.NPR"];

export default connect(function (store: any) {
    return {
        project: getCurrentProject(store),
        insulatedGlassUnits: getInsulatedGlassUnits(store),
        selectedModel: getSelectedModelVersion(store),
        isHighLodMode: isHighLodModelViewMode(store),
        isAnalyticsTabVisible: isAnalyticsTabVisible(store),
        isEngineer: isEngineer(store),
        areUnreleasedFeaturesAvailable: areUnreleasedFeaturesAvailable(store),
        editable: isEditable(store),
        allModels: getModels(store),
        userName: getApplicationUserName(store)
    };
}, { toggleHighLodMode, showProcessingPane, reportProcessingProgress, showProcessingError, requireAnalyticsRefresh })(ModelEditorViewer);