import { WorkerAction, WorkerResultMessage } from "./modelsGeneratorWorker";
import { EventDispatcher } from "../eventBus/eventDispatcher";
import repo from "../../../Repository";

export class ModelsGenerator {
    private readonly worker: Worker;

    constructor(private readonly eventBus: EventDispatcher, readonly modelId: string) {
        this.worker = new Worker(new URL("./modelsGeneratorWorker.ts", import.meta.url), { type: "module" });

        this.onWorkerMessage = this.onWorkerMessage.bind(this);
        this.worker.onmessage = this.onWorkerMessage;

        this.postWorkerMessage({ action: "initialize", accessToken: repo.getAccessToken() });
    }

    public createPanelType(panelId: string) {
        this.postWorkerMessage({
            action: "create-panel-type",
            id: panelId,
        });
    }

    public createCornerPanelType(cornerId: string) {
        this.postWorkerMessage({
            action: "create-corner-type",
            id: cornerId,
        });
    }

    public createMissingTypes(modelId: string) {
        this.postWorkerMessage({
            action: "create-missing-model-types",
            modelId,
        });
    }

    public trackUnprocessed(panelIds: string[], cornerIds: string[]) {
        this.postWorkerMessage({
            action: "track-unprocessed-types",
            panelIds,
            cornerIds,
        });
    }

    public dispose() {
        this.worker.terminate();
    }

    private onWorkerMessage(event: MessageEvent<WorkerResultMessage>) {
        const data = event.data;

        switch (data.action) {
            case "single-panel-type-generation-started": {
                this.eventBus.dispatchEvent({
                    type: "Dextall.TypesManager.Generation.PanelType.Started",
                    payload: data.panels,
                });
                break;
            }

            case "single-corner-type-generation-started": {
                this.eventBus.dispatchEvent({
                    type: "Dextall.TypesManager.Generation.CornerType.Started",
                    payload: data.corners,
                });
                break;
            }

            case "missing-model-types-generation-started": {
                this.eventBus.dispatchEvent({
                    type: "Dextall.TypesManager.Generation.Model.Started",
                    payload: data,
                });
                break;
            }

            case "generation-completed": {
                this.eventBus.dispatchEvent({
                    type: "Dextall.TypesManager.Generation.Completed",
                    payload: data,
                });
                break;
            }

            case "operation-failed": {
                this.eventBus.dispatchEvent({
                    type: "Dextall.TypesManager.GenerationRequest.Failed",
                    payload: data,
                });
                break;
            }
        }
    }

    private postWorkerMessage(message: WorkerAction) {
        this.worker.postMessage(message);
    }
}
